<template>
  <section class="form-wrap">
    <div class="form-content">
      <el-select
        class="base-select"
        v-model="clientStageId"
        placeholder="请选择客户阶段"
        filterable
        :loading="submitting"
      >
        <el-option
          v-for="item in stageOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="form-content">
      <el-button @click="onCancel" :disabled="submitting">取消</el-button>
      <el-button type="primary" @click="onConfirm" :loading="submitting">{{submitting ? '提交中...' : '确定'}}</el-button>
    </div>
  </section>
</template>

<script>
import { ShowApiError } from "@/request/error";
import { PutClientStage } from "../api";
import { GetClientStagesAll } from "@/views/client/clientStages/api";
export default {
  props: {
    clientId: {
      type: [String, Number],
      default: null,
    },
    stageId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      submitting: false,
      clientStageId: null,
      stageOptions: [],
    };
  },
  watch: {
    stageId:{
        handler(val){
            this.clientStageId = val;
        },
        immediate: true
    },
  },
  created() {
    this.getClientStageOptions();
  },
  methods: {
    getClientStageOptions() {
      GetClientStagesAll()
        .then((res) => {
          this.stageOptions = res.data;
        })
        .catch((err) => {
          ShowApiError("获取阶段选单失败", err);
        });
    },
    onStageChange() {
      if (this.clientId === "" || this.clientId === null) {
        this.$message.error("客户id不能为空!");
        return false;
      }
      this.submitting = true;
      PutClientStage(this.clientId, this.clientStageId)
        .then(() => {
          this.submitting = false;
          this.$message.success("操作成功");
          this.$emit("success");
        })
        .catch((err) => {
          this.submitting = false;
          ShowApiError("变更阶段失败", err);
        });
    },
    onConfirm() {
      this.onStageChange();
    },
    onCancel() {
      this.$emit("cancel");
      this.clientStageId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-content{
    padding-top: 30px;
    text-align: right;
}
</style>