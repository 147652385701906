<template>
  <div class="user-reapet-table-wrapper">
    <el-popover
      v-if="isRepeated"
      placement="left"
      width="500"
      trigger="click"
      v-model="togglePopover"
    >
      <!-- 这里将会有表格数据 -->
      <el-table :data="tableData" v-loading="loading" height="300px">
        <el-table-column
          property="name"
          label="客户名称"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span
              style="padding-left: 5px"
              title="点击查看详情"
              class="text-nav"
              @click="onShowDetail(scope.row)"
              >{{ scope.row.name }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          property="contactName"
          label="联系人"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
              scope.row.mainContact !== null ? scope.row.mainContact.name : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          property="ownerName"
          label="负责人"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.ownerName }}
          </template>
        </el-table-column>
        <el-table-column property="contactTelephoneNo" label="联系电话">
          <template slot-scope="scope">
            {{
              scope.row.mainContact !== null
                ? scope.row.mainContact.phoneNo
                : ""
            }}
          </template>
        </el-table-column>
      </el-table>
      <i class="el-icon-connection text-nav" slot="reference"></i>
    </el-popover>
    <span>{{ userPhone !== null ? userPhone : "/" }}</span>
  </div>
</template>

<script>
import { GetClientsList, GetClientRepeatedCheck } from "../api";
import { isEmprty } from "@/utils/validate";
export default {
  props: {
    userPhone: {
      type: [String, Number],
      default: null,
    },
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      togglePopover: false,
      isRepeated: false,
      loading: false,
      tableData: [],
    };
  },
  watch: {
    id: {
      handler() {
        if (!isEmprty(this.id)) {
          this.onQueryClientReapeated();
        }
      },
      immediate: true,
    },
    togglePopover: {
      handler(val) {
        if (this.isRepeated && val) {
          this.getReapetUser();
        }
      },
      immediate: true,
    },
  },
  methods: {
    getReapetUser() {
      this.loading = true;
      GetClientsList({
        page: 0,
        size: 250,
        repeated: true,
        "contacts.phoneNo": this.userPhone,
      })
        .then((res) => {
          this.loading = false;
          let { content } = res.data;
          this.tableData = content;
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
        });
    },
    onQueryClientReapeated() {
      GetClientRepeatedCheck(this.id)
        .then((res) => {
          if (res.data) {
            this.isRepeated = true;
          } else {
            this.tableData = [];
            this.isRepeated = false;
          }
        })
        .catch(() => {
          this.tableData = [];
          this.isRepeated = false;
        });
    },
    onShowDetail(row) {
      this.$router.push({
        name: "CientsDetail",
        params: {
          id: row.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-reapet-table-wrapper {
  box-sizing: border-box;
  width: 100%;
}
</style>