import { ReadLocalColumn } from "@/utils/table";
export default {
  data() {
    return {
      tableColumns: []
    }
  },
  methods: {
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        let columns = [
          {
            prop: "star",
            label: "星标",
            showColumn: true,
            fixed: "left",
            width: "60px",
            align: "center",
            sortable: false,
          },
          {
            prop: "disabled",
            label: "状态",
            showColumn: true,
            width: 90,
            fixed: "left",
            align: "center",
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建时间",
            showColumn: true,
            width: 120,
            align: "center",
            fixed: null,
            sortable: "custom",
          },
          {
            prop: "name",
            label: "客户名称",
            showColumn: true,
            fixed: null,
            minWidth: "120px",
            sortable: "custom",
          },
          {
            prop: "mainContactName",
            label: "联系人",
            showColumn: true,
            fixed: null,
            minWidth: "100px",
            sortable: false,
          },
          {
            prop: "mainContactPhoneNo",
            label: "联系电话",
            showColumn: true,
            fixed: null,
            minWidth: "120px",
            sortable: "custom",
          },
          {
            prop: "address.province",
            label: "省份",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "address.city",
            label: "城市",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "address.region",
            label: "地区",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "address.street",
            label: "街道/市场",
            showColumn: true,
            width: 120,
            fixed: null,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "brand",
            label: "品牌",
            showColumn: true,
            width: 130,
            fixed: null,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "ownerName",
            label: "负责人",
            showColumn: true,
            minWidth: "120px",
            sortable: "custom",
          },
          {
            prop: "sourceName",
            label: "客户来源",
            showColumn: true,
            width: 120,
            sortable: "custom",
          },
          {
            prop: "typeName",
            label: "类型",
            showColumn: true,
            minWidth: "120px",
            sortable: "custom",
          },
          {
            prop: "stageName",
            label: "客户阶段",
            showColumn: true,
            minWidth: "120px",
            sortable: "custom",
          },
          {
            prop: "followUpStatusName",
            label: "跟进状态",
            minWidth: "120px",
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "followUpStatusCount",
            label: "跟进记录",
            align: "center",
            minWidth: 80,
            showColumn: true,
            sortable: false,
          },

          {
            prop: "ownerExpiredAt",
            label: "负责人失效时间",
            showColumn: true,
            width: 150,
            align: "center",
            sortable: "custom",
          },
          
          
          
          {
            prop: "tags",
            label: "标签",
            showColumn: true,
            minWidth: 150,
            fixed: null,
            align: "center",
            sortable: false,
          },
          {
            prop: "address.country",
            label: "国家",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "isFetched",
            label: "自动拉取",
            showColumn: true,
            width: 80,
            fixed: null,
            align: "center",
            sortable: false,
          },
          {
            prop: "isClaim",
            label: "认领",
            showColumn: true,
            width: 80,
            fixed: null,
            align: "center",
            sortable: false,
          },
          {
            prop: "remark",
            label: "备注",
            showColumn: true,
            minWidth: 150,
            fixed: null,
            align: "center",
            sortable: false,
          },
          {
            prop: "createdByName",
            label: "创建人",
            align: "center",
            showColumn: false,
            width: 120,
            sortable: "custom",
          },
          {
            prop: "lastModifiedAt",
            label: "最后修改日期",
            showColumn: false,
            width: 200,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "lastModifiedByName",
            label: "最后修改人",
            align: "center",
            showColumn: false,
            width: 120,
            sortable: "custom",
          },
          {
            prop: "contacts",
            label: "更多联系人",
            align: "center",
            showColumn: false,
            width: 120,
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            showColumn: true,
            label: "操作",
            width: 160,
          },
        ];
        this.tableColumns = ReadLocalColumn(this.$route, columns);
      }
    },
  }
}