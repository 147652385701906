<template>
  <section class="form-wrap">
    <div class="form-content">
      <el-select
        class="base-select"
        v-model="clientOwnerId"
        placeholder="请选择客户负责人"
        filterable
        :loading="submitting"
      >
        <el-option
          v-for="item in ownerOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="form-content">
      <el-button @click="onCancel" :disabled="submitting">取消</el-button>
      <el-button type="primary" @click="onConfirm" :loading="submitting">{{
        submitting ? "提交中..." : "确定"
      }}</el-button>
    </div>
  </section>
</template>

<script>
import { ShowApiError } from "@/request/error";
import { PutClientOwner } from "../api";
import { GetClientOwnersAll } from "@/views/client/clientOwners/api";
export default {
  props: {
    clientId: {
      type: [String, Number],
      default: null,
    },
    ownerId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      submitting: false,
      clientOwnerId: null,
      ownerOptions: [],
    };
  },
  watch: {
    ownerId: {
      handler(val) {
        this.clientOwnerId = val;
      },
      immediate: true,
    },
  },
  created() {
    this.getClientOwnerOptions();
  },
  methods: {
    getClientOwnerOptions() {
      GetClientOwnersAll()
        .then((res) => {
          this.ownerOptions = res.data;
        })
        .catch((err) => {
          ShowApiError("获取负责人选单失败", err);
        });
    },
    onOwnerChange() {
      if (this.clientId === "" || this.clientId === null) {
        this.$message.error("客户id不能为空!");
        return false;
      }
      this.submitting = true;
      PutClientOwner(this.clientId, this.clientOwnerId)
        .then(() => {
          this.submitting = false;
          this.$message.success("操作成功");
          this.$emit("success");
        })
        .catch((err) => {
          this.submitting = false;
          ShowApiError("变更阶段失败", err);
        });
    },
    onConfirm() {
      this.onOwnerChange();
    },
    onCancel() {
      this.$emit("cancel");
      this.clientOwnerId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-content {
  padding-top: 30px;
  text-align: right;
}
</style>