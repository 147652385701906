<template>
  <section class="form-wrap">
    <div class="client-name-list" v-if="multipleSelected.length > 0">
      <el-tag type="info" v-for="item in multipleSelected" :key="item.id" style="margin-top:10px;margin-right:10px">{{item.name}}</el-tag>
    </div>
    <div class="form-content">
      <el-select
        class="base-select"
        v-model="clientOwnerId"
        placeholder="请选择客户负责人"
        filterable
        :loading="submitting"
      >
        <el-option
          v-for="item in ownerOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="form-content">
      <el-button @click="onCancel" :disabled="multipleOwnerLoading"
        >取消</el-button
      >
      <el-button
        type="primary"
        @click="onConfirm"
        :loading="multipleOwnerLoading"
        >{{ multipleOwnerLoadingText }}</el-button
      >
    </div>
  </section>
</template>

<script>
import { ShowApiError } from "@/request/error";
import { MultipleOwnerClients, GetUpdateOwnerCheck } from "../api";
import { GetClientOwnersAll } from "@/views/client/clientOwners/api";
import { FormatMultipleItemIds, GetTableDisableMessage } from "@/utils/table";
export default {
  props: {
    multipleSelected: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      multipleOwnerLoading: false,
      multipleOwnerLoadingText: "确定",
      submitting: false,
      clientOwnerId: null,
      ownerOptions: [],
    };
  },
  created() {
    this.getClientOwnerOptions();
  },
  methods: {
    getClientOwnerOptions() {
      GetClientOwnersAll()
        .then((res) => {
          this.ownerOptions = res.data;
        })
        .catch((err) => {
          ShowApiError("获取负责人选单失败", err);
        });
    },
    onCheckItemOwner() {
      return new Promise((resolve) => {
        this.multipleSelected.forEach((item) => {
          item.canUpdateOwnerChecking = true;
          GetUpdateOwnerCheck(item.id)
            .then((res) => {
              if (res.data) {
                item.canOwner = res.data;
              }
            })
            .finally(() => {
              item.canUpdateOwnerChecking = false;
            });
        });
        let timer = null;
        timer = setInterval(() => {
          let flag = this.multipleSelected.every(
            (item) => item.canUpdateOwnerChecking === false
          );
          if (flag) {
            clearInterval(timer);
            resolve();
          }
        }, 1000);
      });
    },
    // 批量设置负责人
    onMultipleSetOwner() {
      if (this.multipleSelected.length <= 0) {
        return false;
      }
      this.multipleOwnerLoading = true;
      this.multipleOwnerLoadingText = "正在校验操作许可...";
      this.onCheckItemOwner().then(() => {
        let { ids, nameString, unableControl } = FormatMultipleItemIds({
          multipleSelectes: this.multipleSelected,
          nameKey: "name",
          conditionsKey: "canOwner",
        });
        let message = GetTableDisableMessage({
          total: this.multipleSelected.length,
          ableControlCount: ids.length,
          unableControlCount: unableControl.length,
          nameString: nameString,
          flag: true,
          ableText: "设置负责人",
          unAbleText: "设置负责人",
        });
        this.multipleOwnerLoadingText = "检验完成，请确认...";
        this.$msgbox({
          title: `设置负责人提示`,
          message: message,
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              if (ids.length <= 0) {
                this.$message.info("无有效数据可操作");
                done();
                this.multipleOwnerLoading = false;
                this.multipleOwnerLoadingText = "确定";
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                return;
              }
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = "执行中...";
              MultipleOwnerClients(ids, this.clientOwnerId)
                .then(() => {
                  this.$message.success("操作成功");
                  this.$emit("success");
                })
                .catch((err) => {
                  ShowApiError("操作请求错误",err);
                })
                .finally(() => {
                  done();
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "";
                  this.multipleOwnerLoading = false;
                  this.multipleOwnerLoadingText = "确定";
                });
            } else {
              this.multipleOwnerLoading = false;
              this.multipleOwnerLoadingText = "确定";
              instance.confirmButtonLoading = false;
              instance.confirmButtonText = "";
              done();
            }
          },
        });
      });
    },
    onConfirm() {
      if (this.clientOwnerId === "" || this.clientOwnerId === null) {
        this.$message.error("请先选择负责人!");
        return false;
      }
      this.onMultipleSetOwner();
    },
    onCancel() {
      this.$emit("cancel");
      this.clientOwnerId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-content {
  padding-top: 30px;
  text-align: right;
}
</style>